import app from 'firebase/app';
import 'firebase/firestore';

import { EnrollmentStatus } from '../../constants/enrollment';
const COLL_NAME_ENROLLMENT = "enrollments";

class EnrollmentData {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_ENROLLMENT);
    }

    fetchQueue = (onData, onError) => {
        let query = this.db.where('active', '==', true).orderBy("metadata.created", "desc");
        return query.onSnapshot(querySnapshot => {
            let entries = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            onData(entries);
        },
            error => {
                onError(error);
            });
    }

    countPendingEnrolls = (onNumber, onError, customStatus) => {
        let query = this.db.where("status", "==", EnrollmentStatus.pending);
        if (customStatus) {
            query = this.db.where("status", "==", customStatus);
        }
        return query.onSnapshot(querySnapshot => {
            onNumber(querySnapshot.size);
        },
            error => {
                onError(error);
            });
    }

    updateEnrollmentWithJiraIssue = (enrollmentId, issueSelfLink) => {
        return this.db.doc(enrollmentId).update({ jiraIssue: issueSelfLink }).catch(error => {
            console.error(error);
        });
    }
}

export default EnrollmentData;