import React, { Component } from "react";
import "./visitView.css";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import Moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import { AuthUserContext } from "../../Session";

import { Card, Grid, Icon, Label, Button, Modal, Header, Image } from "semantic-ui-react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import DeleteModal from "./deleteModal";
import VisitMetadataGlance from "./visitItemMetadataGlance";
import ThumbnailPanel from "./visitItemThumbnailPanel";
import VisitDetails from "./visitDetails";
import * as ROUTES from "../../../constants/routes";


class VisitView extends Component {
  constructor(props) {
    super(props);

    this.visitData = props.visit.data;

    this.state = {
      openDeleteModal: false,
      deleteWorking: false,
      isSomoneElse: false,
      isDetailsModalOpened: false,
      copiedLink: false,
      compareWith: null,
      isComparedTo: false,
      headerFaceIndex: 0,
      imageIndex: 0,
    }
  }

  /* 
  * Will replace the componentWillReciveProps(...) in React v17.
  *
  * This method recives the next and previous props sent to this component and the value it returns is set into the state 
  * (similar to setState({...});)
  */
  static getDerivedStateFromProps(nextProps, prevProps) {
    if (!nextProps.compareWith)
      return { isComparedTo: false }
    return null
  }

  deleteThisVisit = (imageUrl) => {
    let visitData = {
      visitID: this.props.visit.id,
      thumbnailURL: imageUrl,
    };
    this.setState({ deleteWorking: true, openDeleteModal: false });
    this.props.deleteVisit(visitData);
  }


  showDeleteModal = () => this.setState({ openDeleteModal: true })
  deleteCanceled = () => this.setState({ openDeleteModal: false })

  showDetailsModal = () => this.setState({ isDetailsModalOpened: true });
  detailsModalClosed = () => this.setState({ isDetailsModalOpened: false });

  setVisitCompared = () => {
    if (this.state.isComparedTo) {
      this.setState({ isComparedTo: false });
      this.props.setVisitCompared(null);
    }
    else {
      this.setState({ isComparedTo: true });
      this.props.setVisitCompared(this.props.visit.id);
    }
  }

  doCompare = () => {
    let win = window.open(`${window.location.protocol}//${window.location.hostname}/v/${this.props.compareWith}/compareWith/${this.props.visit.id}`, '_blank');
    win.focus();
    this.setState({ isComparedTo: false });
    this.props.setVisitCompared(null);
  }

  goToVisitsHistory = () => {
    let win = window.open(`${window.location.protocol}//${window.location.hostname + ROUTES.VISITS_BROWSER}?uuid=${this.props.visit.data.person.uuid}`, '_blank');
    win.focus();
  }

  headerFaceError = () => {
    let newHeaderFaceIndex = this.state.headerFaceIndex + 1;
    if (newHeaderFaceIndex < this.props.visit.data.images.length) {
      this.setState({ headerFaceIndex: newHeaderFaceIndex });
    }
  }

  onThumbnailError = () => {
    let newImageIndex = this.state.imageIndex + 1;
    if (newImageIndex < this.props.visit.data.images.length) {
      this.setState({ imageIndex: newImageIndex });
      return true;
    }
    return false;
  }

  render() {
    let { visit, editMode, children, allowComparison, compareWith } = this.props;
    const { isComparedTo, headerFaceIndex, imageIndex } = this.state;


    let data = visit.data;
    let person = data.person;
    let location = data.location;
    let visitMoment = Moment(data.timestamp.toDate()).tz(location.timezone);
    let imageUrl = (this.visitData.images.length > 0) ? `${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${this.visitData.images[imageIndex].mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}` : '';
    let fullImageUrl = imageUrl + '&size=size_orig';
    let faceRect = this.visitData.images[imageIndex].faceRect;
    return (
      <div>
        <Card fluid color={isComparedTo ? "violet" : "orange"}>
          <Card.Content>
            <Image floated="left" rounded src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/` + data.images[headerFaceIndex].mediaUuid + "/faces/" + data.images[headerFaceIndex].faceId + `?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}`} style={{ maxHeight: "75px", marginBottom: "0px" }} onError={this.headerFaceError} />
            <Card.Header>
              <Label attached='top right'>
                Visit ID
                <CopyToClipboard text={visit.id} onCopy={() => this.setState({ flag_copiedToClpBoardVid: true })}>
                  <Label.Detail>{visit.id} {this.state.flag_copiedToClpBoardVid && <Icon inverted color="green" name="clipboard check" />}</Label.Detail>
                </CopyToClipboard>
              </Label>
              {visitMoment.format('LLLL')}
            </Card.Header>
            <Card.Meta><h4>At {location.name + ", " + location.region + " "} </h4></Card.Meta>
          </Card.Content>
          <Card.Content>
            <Grid stackable columns={2}>
              <Grid.Column>
                {children &&
                  <Grid.Row stretched textAlign='center' >
                    <ThumbnailPanel imageSrcUrl={imageUrl} faceRect={faceRect} fullImageSrcUrl={fullImageUrl} onError={this.onThumbnailError} />
                  </Grid.Row>
                }
                <Grid.Row>
                  <br />
                  <VisitMetadataGlance personData={person} idents={data.identifications} confidence={{ max: data.visitConfidence, avg: data.averageConfidence }} />
                </Grid.Row>
              </Grid.Column>
              {!children ?
                <Grid.Column verticalAlign='middle'>
                  <Grid.Row stretched textAlign='center' >
                    <ThumbnailPanel imageSrcUrl={imageUrl} faceRect={faceRect} fullImageSrcUrl={fullImageUrl} maxConfidence={data.visitConfidence} onError={this.onThumbnailError} />
                  </Grid.Row>
                </Grid.Column>
                :
                <Grid.Column>
                  <Grid.Row stretched >
                    {children}
                  </Grid.Row>
                </Grid.Column>
              }

            </Grid>
          </Card.Content>
          <Card.Content extra>
            <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/v/${visit.id}`} onCopy={() => this.setState({ copiedLink: true })}>
              <Button icon labelPosition='left' onBlur={() => this.setState({ copiedLink: false })}>
                <Icon name={this.state.copiedLink ? 'check' : 'chain'} color='purple' />Get link
              </Button>
            </CopyToClipboard>
            <Button icon
              labelPosition='left'
              onClick={this.showDetailsModal}>
              <Icon name='list' color='blue' />More details</Button>
            {editMode && <Button icon
              loading={this.state.deleteWorking}
              disabled={this.state.deleteWorking}
              labelPosition='left'
              onClick={this.showDeleteModal}>
              <Icon name='trash' color='red' />Delete</Button>
            }
            {
              allowComparison && <Button icon
                labelPosition='left'
                onClick={compareWith && !isComparedTo ? this.doCompare : this.setVisitCompared}
                floated="right"
                color={compareWith && !isComparedTo ? "violet" : null}>
                <Icon name='columns' color={compareWith && !isComparedTo ? null : "violet"} />
                {compareWith && !isComparedTo ? "Compare to this visit" : isComparedTo ? "Cancel" : "Compare to visit"}
              </Button>
            }
            <AuthUserContext.Consumer>
              {
                authUser =>
                  <>
                    {authUser.isAdmin &&
                      <Button icon
                        labelPosition='left'
                        floated="right"
                        onClick={this.goToVisitsHistory}>
                        <Icon name='history' color='black' />Show previous visits</Button>}
                  </>
              }
            </AuthUserContext.Consumer>
          </Card.Content>
        </Card>

        <Modal size='large' open={this.state.isDetailsModalOpened}>
          <Modal.Header>
            <Header as="h4">
              <Header.Content>
                Showing details of visit from {Moment(data.timestamp.toDate()).tz(location.timezone).format('LLLL')}
                <Header.Subheader>in {location.name + ", " + location.region}</Header.Subheader>
              </Header.Content>
            </Header>
          </Modal.Header>
          <Modal.Content scrolling>
            <Label attached='top right'>
              Visit ID: <Label.Detail>{visit.id} </Label.Detail>
            </Label>
            <VisitDetails visit={visit} />
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="black" onClick={this.detailsModalClosed}>
              <Icon name='x' /> Close
            </Button>
          </Modal.Actions>
        </Modal>

        <DeleteModal
          open={this.state.openDeleteModal}
          action={() => this.deleteThisVisit(imageUrl)}
          onCancel={this.deleteCanceled}
          visitDetails={
            {
              visitID: this.props.id,
              thumbnailURL: imageUrl,
              datetime: Moment(data.timestamp.toDate()).tz(location.timezone).format('LLLL'),
              location: location.name + ", " + location.region + " (" + location.country + ")"
            }
          } />
      </div>
    );
  }
}

export default compose(
  withFirebase,
  withRouter
)(VisitView);
