import React, { Component } from 'react';
import { Image, Table, Icon, Label, Grid, Header, Message } from 'semantic-ui-react';
import { FeedbackTypes } from "../../../../constants/feedback";
import { PersonRoles } from "../../../../constants/roles";
import { PropTypes } from "prop-types";

import Moment from "moment-timezone";
import IdentificationsList from '../../../Visit/VisitView/identificationsList';
import UUID from '../../../UI/uuid';

const gridRowStyle = { paddingBottom: '4px', paddingTop: '4px' };



class FeedbackSummaryForVisits extends Component {
    static propTypes = {
        feedbackData: PropTypes.object.isRequired,  // Report's feedback metadata
        visits: PropTypes.array.isRequired,         // the visits in the report for additional data
        notesData: PropTypes.object,                //notesData: Notes in this report
        slim: PropTypes.bool,                       //slim: Slim lines (shows only one face instead of thumbnail)
    }

    openFullResImage = imageUuid => {
        let win = window.open(`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${imageUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`, '_blank');
        win.focus();
    }

    getVisit = (visitID, visits) => {
        for (let key in visits) {
            if (visits[key].id === visitID) {
                return visits[key];
            }
        }
        return null;
    }

    render() {
        let { feedbackData, notesData, visits, slim } = this.props;
        return (
            <Table celled color={!slim ? 'black' : null} basic={slim ? "very" : null}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={slim ? "1" : "2"} textAlign="center"><Icon name='user circle outline' /></Table.HeaderCell>
                        <Table.HeaderCell width={slim ? "7" : "6"}>{slim ? "Info" : "Visit Details"}</Table.HeaderCell>
                        <Table.HeaderCell width={slim ? "6" : "7"}>{slim ? "Notes" : "Info"}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {visits.map(visit => {
                        if (slim) {
                            return (
                                <Table.Row key={visit.id}>
                                    {!!feedbackData[visit.id] && <SlimFeedbackTableRow feedback={feedbackData[visit.id]} note={notesData[visit.id]} visit={visit} openFullResImage={this.openFullResImage} />}
                                    {!feedbackData[visit.id] && !!notesData[visit.id] && <SlimNotesTableRow note={notesData[visit.id]} visit={visit} openFullResImage={this.openFullResImage} />}
                                </Table.Row>
                            );
                        }
                        return (
                            <Table.Row key={visit.id}>
                                {!!feedbackData[visit.id] && <FeedbackTableRow feedback={feedbackData[visit.id]} note={notesData[visit.id]} visit={visit} openFullResImage={this.openFullResImage} />}
                                {!feedbackData[visit.id] && !!notesData[visit.id] && <NotesTableRow note={notesData[visit.id]} visit={visit} openFullResImage={this.openFullResImage} />}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    }
}

const NotesTableRow = props => {
    let { note, visit, openFullResImage } = props;
    return (
        <>
            <Table.Cell textAlign="center">
                <center>
                    <Image
                        rounded
                        size='medium'
                        src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${visit.data.images[0].mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`}
                        onClick={() => openFullResImage(visit.data.images[0].mediaUuid)}
                    />
                </center>
            </Table.Cell>
            <Table.Cell verticalAlign='top'>
                <Grid padded>
                    <Grid.Row style={gridRowStyle}>
                        <Header as="h5">Visit ID: <Header.Subheader>{visit.id}</Header.Subheader></Header>
                    </Grid.Row>
                    <Grid.Row style={gridRowStyle}>
                        <Header as="h5">
                            {/*UUID: <Label horizontal color={feedbackItem.visitRole === PersonRoles.default ? "orange" : null}>{feedbackItem.visitRole === "default" ? "customer" : feedbackItem.visitRole}</Label> <Label horizontal>{parseFloat(Math.round(visit.data.visitConfidence * 10000) / 100).toFixed(2) + "%"}</Label>*/}
                            <Header.Subheader>
                                <UUID value={visit.data.person.uuid} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Row>
                    <Grid.Row style={gridRowStyle}>
                        <IdentificationsList list={visit.data.images.slice(0, 3)} maxConfidence={visit.data.visitConfidence} />
                    </Grid.Row>
                </Grid>
            </Table.Cell>
            <Table.Cell verticalAlign='top' >
                <Grid padded>
                    <Grid.Row style={gridRowStyle}>
                        <Message warning><cite>{note.text}</cite></Message>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </>
    );
}

const SlimImageSize = { width: '100%' };
const SlimRowStyle = { padding: '3px 7px' };

const SlimNotesTableRow = props => {
    let { note, visit, openFullResImage } = props;
    return (
        <>
            <Table.Cell textAlign="center" style={SlimRowStyle}>
                <center>
                    <Image
                        rounded
                        style={SlimImageSize}
                        src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${visit.data.images[0].mediaUuid}/faces/${visit.data.images[0].faceId}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`}
                        onClick={() => openFullResImage(visit.data.images[0].mediaUuid)}
                    />

                </center>
            </Table.Cell>
            <Table.Cell verticalAlign='middle' textAlign="center" style={SlimRowStyle}>
                <Header.Subheader>
                    No additional feedback info to show
                </Header.Subheader>
            </Table.Cell>
            <Table.Cell verticalAlign='top' style={SlimRowStyle} >
                <Grid padded>
                    <Grid.Row style={gridRowStyle} >
                        <cite>{note.text}</cite>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </>
    );
}

const FeedbackTableRow = props => {
    let { feedback, note, visit, openFullResImage } = props;
    let isFalsePositive = feedback.visitRole !== PersonRoles.anonymous && !feedback.identifiedResult;
    let isEnrollment = feedback.visitRole === PersonRoles.anonymous && feedback.type !== FeedbackTypes.unknown;
    return (
        <>
            {
                visit && (isFalsePositive || isEnrollment) ?
                    <>
                        <Table.Cell textAlign="center">
                            <center>
                                <Image
                                    rounded
                                    size='medium'
                                    src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${visit.data.images[0].mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`}
                                    onClick={() => openFullResImage(visit.data.images[0].mediaUuid)}
                                />
                            </center>
                        </Table.Cell>
                        <Table.Cell verticalAlign='top'>
                            <Grid padded>
                                <Grid.Row style={gridRowStyle}>
                                    <Header as="h5">Visit ID: <Header.Subheader>{visit.id}</Header.Subheader></Header>
                                </Grid.Row>
                                <Grid.Row style={gridRowStyle}>
                                    <Header as="h5">
                                        UUID: <Label horizontal color={feedback.visitRole === PersonRoles.default ? "orange" : null}>{feedback.visitRole === "default" ? "customer" : feedback.visitRole}</Label> <Label horizontal>{parseFloat(Math.round(visit.data.visitConfidence * 10000) / 100).toFixed(2) + "%"}</Label>
                                        <Header.Subheader>
                                            <UUID value={visit.data.person.uuid} />
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Row>
                                <Grid.Row style={gridRowStyle}>
                                    <IdentificationsList list={visit.data.images.slice(0, 3)} maxConfidence={visit.data.visitConfidence} />
                                </Grid.Row>
                            </Grid>
                        </Table.Cell>
                        <Table.Cell negative={isFalsePositive} verticalAlign='top' >
                            <Grid padded>
                                {isFalsePositive &&
                                    <Grid.Row style={{ padding: "0px" }}>
                                        <b><Icon fitted name="exclamation triangle" />This identification was marked as wrong</b>
                                    </Grid.Row>
                                }
                                {feedback.type !== FeedbackTypes.unknown &&
                                    <Grid.Row>
                                        This visit was identified as the following person:
                                    </Grid.Row>
                                }
                                {feedback.type === FeedbackTypes.unknown &&
                                    <Grid.Row>
                                        This person is unknown
                                    </Grid.Row>
                                }
                                {feedback.type === FeedbackTypes.other &&
                                    <Grid.Row>
                                        {feedback.data}
                                    </Grid.Row>
                                }
                                {feedback.type === FeedbackTypes.candidate &&
                                    <>
                                        <Grid.Row style={gridRowStyle}>
                                            {feedback.data.candidateData.customerName}
                                        </Grid.Row>
                                        <Grid.Row style={gridRowStyle}>
                                            {feedback.data.candidateData.customerId}
                                        </Grid.Row>
                                        <Grid.Row style={gridRowStyle}>
                                            {Moment(feedback.data.candidateData.date).tz(visit.data.location.timezone).format('l, LT')} (sale with{Moment(feedback.data.candidateData.date).tz(visit.data.location.timezone).from(Moment(visit.data.timestamp.toDate()).tz(visit.data.location.timezone))})
                                        </Grid.Row>
                                    </>
                                }
                                {note &&
                                    <Grid.Row style={gridRowStyle}>
                                        <Message warning><cite>{note.text}</cite></Message>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Table.Cell>
                    </>
                    : note && <NotesTableRow note={note} visit={visit} openFullResImage={openFullResImage} />
            }
        </>
    );
}

const SlimFeedbackTableRow = props => {
    let { feedback, note, visit, openFullResImage } = props;
    let isFalsePositive = feedback.visitRole !== PersonRoles.anonymous && !feedback.identifiedResult;
    let isEnrollment = feedback.visitRole === PersonRoles.anonymous && feedback.type !== FeedbackTypes.unknown;
    return (
        <>
            {
                visit && (isFalsePositive || isEnrollment) ?
                    <>
                        <Table.Cell textAlign="center" style={SlimRowStyle}>
                            <center>
                                <Image
                                    rounded
                                    style={SlimImageSize}
                                    src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${visit.data.images[0].mediaUuid}/faces/${visit.data.images[0].faceId}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`}
                                    onClick={() => openFullResImage(visit.data.images[0].mediaUuid)}
                                />
                            </center>
                        </Table.Cell>
                        <Table.Cell negative={isFalsePositive} verticalAlign='top' style={SlimRowStyle}>
                            <Grid padded>
                                {isFalsePositive &&
                                    <Grid.Row style={gridRowStyle}>
                                        <b><Icon fitted name="exclamation triangle" />This identification was marked as wrong</b>
                                    </Grid.Row>
                                }
                                {feedback.type !== FeedbackTypes.unknown &&
                                    <Grid.Row style={gridRowStyle}>
                                        This visit was identified as:
                                        {feedback.type === FeedbackTypes.other && <b>&nbsp;{feedback.data}</b>}
                                        {feedback.type === FeedbackTypes.candidate && <b>&nbsp;{feedback.data.candidateData.customerName}</b>}
                                    </Grid.Row>
                                }
                                {feedback.type === FeedbackTypes.unknown &&
                                    <Grid.Row style={gridRowStyle}>
                                        This person is unknown
                                    </Grid.Row>
                                }
                            </Grid>
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' style={SlimRowStyle} >
                            <Grid padded>
                                {note &&
                                    <Grid.Row style={gridRowStyle}>
                                        <cite>{note.text}</cite>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Table.Cell>
                    </>
                    : note && <SlimNotesTableRow note={note} visit={visit} openFullResImage={openFullResImage} />
            }
        </>
    );
}
export default FeedbackSummaryForVisits;