import React, { Component } from 'react';
//import { PropTypes } from 'prop-types';
import { Card, Image, Icon, Button, Modal, Header } from 'semantic-ui-react';
import VisitDetails from '../../../Visit/VisitView/visitDetails';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FeedbackTypes } from '../../../../constants/feedback';

class VisitCandidateView extends Component {
    state = {
        copiedLink: false,
    }

    onSelected = () => {
        if (this.props.visitCandidate) {
            const { data, id } = this.props.visitCandidate
            let feedbackData = Object.assign({}, data);
            delete feedbackData.identifications;

            var feedbackUpdate = {
                identifiedResult: true,
                visitRole: feedbackData.person.role,
                type: FeedbackTypes.candidate,
                data: {
                    candidateId: id,
                    candidateData: feedbackData,
                }
            };
            this.props.onSelectCallback(id);
            this.props.updateFeedback(feedbackUpdate, this.props.sale.id);

        }
        else {
            this.props.onSelectCallback('none');
            this.props.updateFeedback(null, this.props.sale.id);
        }
    }

    render() {
        const { visitCandidate, editMode, feedbackEdit, selected } = this.props;
        const data = visitCandidate && visitCandidate.data;
        const person = data && data.person;
        const images = data && data.images;
        const image = images && images.length > 0 && images[images.length - 1];

        const strongMatch = !!(visitCandidate && visitCandidate.strongMatch);
        const isSelected = visitCandidate ? selected === visitCandidate.id : selected === 'none' ? true : false;

        let _onSelectClick = () => null;
        if (!feedbackEdit) _onSelectClick = null;
        else if (!visitCandidate) _onSelectClick = this.onSelected;

        return (
            <Card
                style={{ display: 'inline-block', margin: '5px', cursor: feedbackEdit ? 'pointer' : 'default', border: `2px ${isSelected ? '#009358' : strongMatch ? '#F2711C' : feedbackEdit ? 'black' : 'lightgrey'} solid`, width: '154px' }}
                onClick={_onSelectClick}
            >
                {visitCandidate ?
                    <>
                        <Card.Content onClick={visitCandidate && feedbackEdit ? this.onSelected : null}>
                            <Card.Header textAlign="center" style={{ color: strongMatch ? '#F2711C' : 'black' }}><span style={{ float: 'left', marginLeft: '-10px', marginTop: '-7px', color: isSelected ? '#009358' : 'lightgrey' }}><Icon name='check circle outline' /> </span> {data.localDate.split(' ')[1]} {data.localDate.split(' ')[2]}</Card.Header>
                            <Card.Header textAlign="center" style={{ color: strongMatch ? '#F2711C' : 'black' }}>{person.name === 'Unknown' ? <br /> : person.name}</Card.Header>
                        </Card.Content>
                        <Image src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${image.mediaUuid}/faces/${image.faceId}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`} wrapped ui={false} onClick={visitCandidate && feedbackEdit ? this.onSelected : null} style={{ width: '150px' }} />
                        <Card.Content>
                            {editMode &&
                                <Button icon>
                                    <Icon name='trash' color='red' />
                                </Button>
                            }
                            <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/v/${visitCandidate.id}`} onCopy={() => this.setState({ copiedLink: true })}>
                                <Button icon onBlur={() => this.setState({ copiedLink: false })}>
                                    <Icon name={this.state.copiedLink ? 'check' : 'chain'} color='purple' />
                                </Button>
                            </CopyToClipboard>
                            <Modal trigger={<Button icon><Icon name='info' color='blue' /></Button>}>
                                <Modal.Header>{person.name} | {data.localDate.split(' ')[1]} {data.localDate.split(' ')[2]}</Modal.Header>
                                <Modal.Content scrolling>
                                    <VisitDetails visit={visitCandidate} />
                                </Modal.Content>
                            </Modal>
                        </Card.Content>
                    </>
                    :
                    <>
                        <Card.Content style={{ height: '282px' }}>
                            <Card.Header textAlign="center"><span style={{ float: 'left', marginLeft: '-10px', marginTop: '-7px', color: isSelected ? '#009358' : 'lightgrey' }}><Icon name='check circle outline' /> </span>Unknown</Card.Header>
                        </Card.Content>
                    </>
                }
            </Card>
        );
    }
}

export default VisitCandidateView;