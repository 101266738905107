import React from 'react';
//import { Component } from 'semantic-ui-react';
import CustomerRecentPurchases from '../../UI/CustomerView/customerRecentPurchases';
import CustomerRecommendations from '../../UI/CustomerView/customerRecommendations';
import { Header, Grid, Image } from 'semantic-ui-react';

const PersonHeader = props => {
    return (
        <Header textAlign="center">
            {props.person.name}
        </Header>
    );
}

const PriceDisplay = props => {
    const { price, final_price } = props;
    const isOnSale = !!final_price && price !== final_price;
    if (!isOnSale) {
        return <>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</>
    } else {
        return (
            <>
                <s>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</s>
                {' '}
                {final_price.toLocaleString('en-US', { style: "currency", currency: "USD" })}
            </>
        );
    }
}

const ProductHeader = props => {
    return (
        <Grid>
            <Grid.Row columns="2">
                <Grid.Column width="2">
                    <Image src={props.productData.imageUrl} size="small" rounded />
                </Grid.Column>
                <Grid.Column>
                    <Header>{props.productData.name}</Header>
                    <p>Price: <PriceDisplay price={props.productData.price} final_price={props.productData.final_price} /></p>
                    {props.productData.link && <p><a href={props.productData.link} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View on ruti.com</a></p>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

const BetaRecommendationsComparison = props => {
    const { person, productData, productHistory, productRecommendations, betaProductRecommendations  } = props;
    return (
        <>
            {person ? <PersonHeader person={person} /> : <ProductHeader productData={productData} />}
            {person ?
                <CustomerRecentPurchases productHistory={productHistory} person={person} maxItems={10} /> :
                null
            }
            <CustomerRecommendations productRecommendations={productRecommendations} maxItems={10} />
            {betaProductRecommendations && <CustomerRecommendations productRecommendations={betaProductRecommendations} maxItems={10} beta />}
        </>
    );
}

export default BetaRecommendationsComparison;