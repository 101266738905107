import React, { Component } from 'react';
import { compose } from "recompose";
import { withAdminAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";

import EnrollmentQueueList from "./enrollmentQueueList";
import { EnrollmentStatus } from '../../../constants/enrollment';

class EnrollmentQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queue: [],
            isLoading: true,
            queuedCounter: 0,
        }
        this.unsubscribeFromEnrollmentQueue = null;
        this.unsubscribeFromEnrollmentQueuedCounter = null;
    }

    componentDidMount() {
        const firebase = this.props.firebase;
        this.unsubscribeFromEnrollmentQueue = firebase.enrollment.fetchQueue(this.onQueueUpdated, this.onDatabaseError);
        this.unsubscribeFromEnrollmentQueuedCounter = firebase.enrollment.countPendingEnrolls(this.onQueueCounterUpdated, this.onDatabaseError, EnrollmentStatus.queued)
    }

    componentWillUnmount() {
        this.unsubscribeFromEnrollmentQueue();
        this.unsubscribeFromEnrollmentQueuedCounter()
    }

    //--------------- Firebase listeners' callbacks ---------------
    onQueueUpdated = queue => {
        this.setState({ queue: queue, isLoading: false });
    }

    onDatabaseError = error => {
        console.error(error);
    }

    //--------------- onClick callbacks ---------------
    onChangeSubmitted = (enrollID, newName, newCid, newRole, onResult) => {
        this.props.firebase.caazamAPI.enrollUpdate(enrollID, newName, newCid, newRole).then(response => {
            onResult(true, response);
        }).catch(error => {
            onResult(false, error);
        });
    }

    // -------------------- API --------------------

    onEnrollment = enrollID => {
        return this.props.firebase.caazamAPI.enroll(enrollID);
    }

    onNoteEdited = (enrollID, notes) => {
        this.props.firebase.caazamAPI.enrollUpdateNotes(enrollID, notes).catch(error => {
            console.error('Enrollment note has NOT been registerd', enrollID, notes, error);
        });
    }

    onMerge = (enrollID, targetUuid) => {
        return this.props.firebase.caazamAPI.enrollMerge(enrollID, targetUuid);
    }

    onQueueCounterUpdated = count => {
        this.setState({ queuedCounter: count });
    }

    //-------------------------------------------------

    compareItemWithLatestVisit = (enrollVisitsId, uuid, setLoadingCallback, setLabelCallback) => {
        this.props.firebase.visits.fetchLatestByUuid(uuid).then(latestVisitId => {
            setLoadingCallback(false);
            if (latestVisitId) {
                let win = window.open(`${window.location.protocol}//${window.location.hostname}/v/${enrollVisitsId}/compareWith/${latestVisitId}`, '_blank');
                win.focus();
            }
            else {
                setLabelCallback("No previous visits");
            }

        }).catch(error => {
            setLoadingCallback(false);
            setLabelCallback(error.message);
        })
    }

    render() {
        const { isLoading, queue, queuedCounter } = this.state;
        return (
            <EnrollmentQueueList
                isLoading={isLoading}
                queue={queue}
                queuedCounter={queuedCounter}
                onEnrollEdited={this.onChangeSubmitted}
                editNote={this.onNoteEdited}
                onEnrollment={this.onEnrollment}
                cancelEnrollment={(enrollID, reason, note) => this.props.firebase.caazamAPI.enrollCancel(enrollID, reason, note)}
                mergeEnrollment={this.onMerge}
                compareItemWithLatestVisit={this.compareItemWithLatestVisit} />
        );
    }
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(EnrollmentQueue);