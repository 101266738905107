import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Image, Placeholder } from 'semantic-ui-react';
import "./imageView.css";

const ViewModifiers = {
    hidden: "hidden",
    landscape: "thumbnail-landscape",
    portraite: "thumbnail-portrait",
}

class ImageView extends Component {
    state = {
        imgViewClass: ViewModifiers.hidden,
    }

    static propTypes = {
        src: PropTypes.string.isRequired,      //src: Image source --> imageSource
        faceRect: PropTypes.object,            //faceRect: The dimensions of a face in the image
        onError: PropTypes.func,               //onError: Errors handler
    };

    onImageLoaded = ({ target: img }) => {
        if (img.naturalWidth > img.naturalHeight)
            this.setState({ imgViewClass: ViewModifiers.landscape });
        else
            this.setState({ imgViewClass: ViewModifiers.portraite });
    }

    calculateBoxDimensions = faceRect => {
        if (faceRect) {
            let x = faceRect.left * 100 - faceRect.width * 25;
            if (x < 0) x = 0;
            let y = faceRect.top * 100 - faceRect.height * 75;
            if (y < 0) y = 0;
            let w = faceRect.width * 150;
            if ((x + w) > 100) w = 100 - x;
            let h = faceRect.height * 210;
            if ((y + h) > 100) h = 100 - y;
            return {
                left: x + "%",
                top: y + "%",
                width: w + "%",
                height: h + "%"
            }
        }
        else {
            return {};
        }
    }

    render() {
        const { src: imageSrc, faceRect, onError, ...semanticUiProps } = this.props;
        const { imgViewClass } = this.state;
        let boxDimensions = this.calculateBoxDimensions(faceRect);

        return (
            <div className="image-container" {...semanticUiProps}>
                {imgViewClass !== ViewModifiers.hidden && <div className={faceRect && "box"} style={boxDimensions}></div>}
                {imgViewClass === ViewModifiers.hidden &&
                    <Placeholder fluid style={{ height: 300, width: 500, borderRadius: "5px" }}>
                        <Placeholder.Image />
                    </Placeholder>
                }
                <Image
                    className={imgViewClass}
                    src={imageSrc}
                    rounded
                    centered
                    fluid
                    onError={onError}
                    onLoad={this.onImageLoaded}
                />
            </div>
        );
    }
}

export default ImageView;