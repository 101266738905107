import React, { Component } from 'react';
import { Image, Table, Icon, Label, Grid, Header } from 'semantic-ui-react';
import Moment from "moment-timezone";

import { PropTypes } from "prop-types";
import { PersonRoles } from "../../../../constants/roles";
import IdentificationsList from '../../../Visit/VisitView/identificationsList';
import UUID from '../../../UI/uuid';

const gridRowStyle = { paddingBottom: '4px', paddingTop: '4px' };


class DeletedVisits extends Component {
    static propTypes = {
        visits: PropTypes.object.isRequired,        //DELETED visits data
    }

    render() {
        let { visits } = this.props;
        return (
            <Table celled color="red">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width="2" textAlign="center"><Icon name='user circle outline' /></Table.HeaderCell>
                        <Table.HeaderCell width="6">Details</Table.HeaderCell>
                        <Table.HeaderCell width="8">Info</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.keys(visits).map(key => {
                        let data = visits[key];
                        return (
                            <Table.Row key={key}>
                                <Table.Cell textAlign="center">
                                    <Image rounded size="medium" src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${data.images[0].mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`} />
                                </Table.Cell>
                                <Table.Cell>
                                    <Grid padded>
                                        <Grid.Row style={gridRowStyle}>
                                            <Header as="h5">Visit ID: <Header.Subheader>{key}</Header.Subheader></Header>
                                        </Grid.Row>
                                        <Grid.Row style={gridRowStyle}>
                                            <Header as="h5">
                                                UUID:
                                            <Label horizontal color={data.person.role === PersonRoles.default ? "orange" : null}>
                                                    {data.person.role === "default" ? "customer" : data.person.role}
                                                </Label>
                                                <Label horizontal>
                                                    {parseFloat(Math.round(data.visitConfidence * 10000) / 100).toFixed(2) + "%"}
                                                </Label>
                                                <Header.Subheader>
                                                    <UUID value={data.person.uuid} />
                                                </Header.Subheader>
                                            </Header>
                                        </Grid.Row>
                                        <Grid.Row style={gridRowStyle}>
                                            <IdentificationsList list={data.images.slice(0, 3)} maxConfidence={data.visitConfidence} />
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                                <Table.Cell verticalAlign="top">
                                    <Grid padded>
                                        <Grid.Row style={gridRowStyle}>
                                            <Header as="h5">Deleted by {<cite>{data.deletedBy}</cite>}<Header.Subheader>At {Moment(data.operationTimestamp.toDate()).tz(Moment.tz.guess(true)).format('LLL')}</Header.Subheader></Header>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })
                    }
                </Table.Body>
            </Table>
        );
    }
}

export default DeletedVisits;