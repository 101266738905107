
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/firestore';
import 'firebase/functions';
import './messaging';


import CaazamAPI from './caazamAPI'
import CaazamMessaging from './messaging';

import VisitsFeedData from './visitsFeedData';
import LocationsData from './locationsData';
import VisitsData from './visitsData';
import ReportsData from './reportsData';
import UsersData from './usersData';
import EnrollmentData from './enrollmentData';
import SystemStatus from './systemStatus';
import SalesData from './salesData';
import RemoteConfig from './remoteConfig';


const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    appId: process.env.REACT_APP_APP_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROXY_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();

        this.rtdb = app.database();
        this.db = app.firestore();
        this.functions = app.functions();

        this.caazamAPI = new CaazamAPI(app);
        this.messaging = new CaazamMessaging(app);

        this.visitsFeed = new VisitsFeedData();
        this.reports = new ReportsData();
        this.visits = new VisitsData();
        this.sales = new SalesData();
        this.locations = new LocationsData();
        this.users = new UsersData();
        this.enrollment = new EnrollmentData();
        this.systemStatus = new SystemStatus();
        this.remoteConfigStore = new RemoteConfig(app);
    }

    // *** Auth API *** 

    doSignInUserWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => {
        this.messaging.stop().then(() => {
            this.auth.signOut();
        })
    }

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    // *** User API *** 
    user = () => {
        return this.db.collection('users').doc(this.auth.currentUser.uid).get().then(snapshot => {
            return snapshot.data();
        });
    }

    userGetNotifications = () => {
        return this.rtdb.ref('notifications').child(this.auth.currentUser.uid).once('value').then(snapshot => {
            return snapshot.val() && !snapshot.val().disabled;
        });
    }

    userSetNotifications = (enabled) => {
        return this.rtdb.ref('notifications').child(this.auth.currentUser.uid).update({ disabled: !enabled });
    }
}

export default new Firebase();
