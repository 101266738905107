import React, { Component, useState } from 'react';
//import { PropTypes } from 'prop-types';
import Moment from "moment-timezone";

import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import { Modal, Button } from 'semantic-ui-react';
import { RichGrid } from '../CaazamUI';

import RecentItem from './VisitFeedComponents/recentItem';


class AllPurchasedItemsModal extends Component {
    state = {
        productHistory: [],
        loading: true,
    }
    componentDidMount() {
        const { firebase, person } = this.props;
        console.log(person.uuid);
        firebase.caazamAPI.getPersonPurchases({ uuid: person.uuid, customerId: person.customerId }).then(purchases => {
            this.setState({ productHistory: purchases, loading: false });
        }).catch(error => {
            console.error(error);
        });
    }
    render() {
        return (
            <Modal open={true} >
                <Modal.Header>{this.props.person.name} - Recent purchases <Button floated="right" onClick={() => this.props.onModalClose(false)}>Close</Button> </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.state.loading && <center>Loading products history...</center>}
                        {this.state.productHistory && this.state.productHistory.length > 0 &&
                            <center>
                                <div style={{ marginLeft: '150px', marginRight: '150px' }}>
                                    <RichGrid alignment="center" gridSize="5">
                                        <RichGrid.Layout>
                                            {
                                                this.state.productHistory.map((product, index) =>
                                                    <RecentItem product={product} person={this.props.person} key={index} />
                                                )
                                            }
                                        </RichGrid.Layout>
                                    </RichGrid>
                                </div>
                            </center>
                        }
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(AllPurchasedItemsModal);