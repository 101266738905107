import React, { Component } from 'react';
import { List, Image } from 'semantic-ui-react';

class IdentificationsList extends Component {
    render() {
        let { list: images, compact, maxConfidence } = this.props;
        let imageSize = compact ? "mini" : "tiny";
        return (
            <List horizontal className="horizontal-list">
                {images.map(ident => (
                    <List.Item key={ident.mediaUuid} className="hl-item">
                        <Image
                            src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/` + ident.mediaUuid + "/faces/" + ident.faceId + `?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}`}
                            size={imageSize}
                            className={
                                ident.confidence === maxConfidence ? "best-ident-face" : ""
                            }
                            rounded
                        />
                    </List.Item>
                ))}
            </List>
        );
    }
}

export default IdentificationsList;