import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Image, Header, Label, Icon, Button, Modal, Form, Dropdown, Radio, TextArea, Card, Grid } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip'
import Moment from "moment-timezone";
import { Link } from 'react-router-dom';
import JiraIssue from '../../Jira/issue';
import { JiraProjects } from '../../Jira/globals';
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";

import EnrollmentDetails from './enrollementDetails';
import { Note } from "../../UI"

import { EnrollmentStatus, EnrollmentStatusName, EnrollmentLabelColor, EnrollmentCancelReason } from "../../../constants/enrollment";
import EnrollmentMergeModal from './Modals/mergeModal';
import UUID from '../../UI/uuid';
class EnrollmentQueueItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFailureDetails: false,
            isEditModalOpen: false,
            isEditWorking: false,
            editError: null,
            isEnrollWorking: false,
            newRole: props.data.role,
            dismissReason: {
                type: null,
                freeText: null,
            },
            dismissModalIsOpen: false,
            mergeModalIsOpen: false,
            jiraIssueModalIsOpen: false,
            jiraIssueDescription: props.data.notes && props.data.notes.text !== '' ? props.data.notes.text : '',
            isJiraWorking: false,
            newCID: "",
        }
    }
    static propTypes = {
        data: PropTypes.object.isRequired,      //data: the enrollable visit data
        enrollID: PropTypes.string.isRequired,      //enrollID: ID of this enrollment item
        editNote: PropTypes.func.isRequired,      //editNote: Trigger to edit note
        compareItemWithLatestVisit: PropTypes.func.isRequired,      //compareItemWithLatestVisit: Allows to  compare item to person's latest visit
    };

    onEditResult = (isOk, result) => {
        if (isOk)
            this.setState({ isEditModalOpen: false, isEditWorking: false, editError: null });
        else {
            this.setState({ editError: result.message, isEditWorking: false });
        }

    }

    onEdit = (newName, newCID, newRole) => {
        let name = newName, cid = newCID;
        if (newName === "")
            name = this.props.data.name;
        if (newCID === "")
            cid = this.props.data.customerId;
        this.props.onEdit(this.props.enrollID, name, cid, newRole, this.onEditResult);
        this.setState({ isEditWorking: true });
    }

    onEditCancel = () => {
        this.setState({ isEditModalOpen: false, isEditWorking: false, editError: null, newCID: "" });
    }

    onEnrollClick = enrollID => {
        this.setState({ isEnrollWorking: true });
        this.props.onEnrollment(enrollID).then(response => {
            this.setState({ enrollError: null });
        }).catch(error => {
            this.setState({ enrollError: error.message, isEnrollWorking: false });
        })
    }

    checkIcon = checks => {
        let showFailureDetails = this.state.showFailureDetails;

        const nullDataIcon = "question circle";
        const nullDataTip = "Not available yet";
        const nullDtatColor = "grey"

        const validDataIcon = "check circle";
        const validDataTip = "Valid";
        const validDataColor = "green";

        const errorDataIcon = "ban";
        const errorDataTip = "Cannot run checks on this entry";
        const errorDataColor = "red";

        const invalidDataIcon = "exclamation circle";
        const invalidDataTip = "Click for more details";
        const invalidDataHandler = () => this.setState({ showFailureDetails: !showFailureDetails });
        const invalidDataColor = "red";

        let cIcon = {
            name: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            customerId: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            confidence: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            visit: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
        };
        if (checks && checks.checkStatus) {
            cIcon = {
                name: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                customerId: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                confidence: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                visit: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
            };
            let cStat = checks.checkStatus;
            if (cStat.name) {
                cIcon.name = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
            if (cStat.customerId) {
                cIcon.customerId = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
            if (cStat.confidence) {
                cIcon.confidence = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
            if (cStat.visit) {
                cIcon.visit = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
        }
        else if (checks && checks.error) {
            cIcon = {
                name: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                customerId: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                confidence: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                visit: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
            };
        }
        return cIcon;
    }

    onNoteEdited = note => {
        this.props.editNote(this.props.enrollID, note);
    }

    onDismissReasonSelected = (e, { value }) => {
        let reason = this.state.dismissReason;
        reason.type = value;
        this.setState({ dismissReason: reason });
    }

    onFreeTextDismissReasonChanged = text => {
        let reason = this.state.dismissReason;
        reason.freeText = text === "" ? null : text;
        this.setState({ dismissReason: reason });
    }

    doDismissEnroll = confirmation => {
        if (confirmation) {
            let reason = this.state.dismissReason;
            this.props.cancelEnrollment(this.props.enrollID, reason.type, reason.freeText).then(response => {
                this.setState({ enrollError: null, isEnrollWorking: false });
            }).catch(error => {
                this.setState({ enrollError: error.message, isEnrollWorking: false });
            });
            this.setState({ dismissModalIsOpen: false, isEnrollWorking: true });
        }
        else {
            this.setState({ dismissModalIsOpen: false });
        }
    }

    doMerge = (confirmation, targetUuid) => {
        if (confirmation) {
            //            ** Enroll **
            this.props.mergeEnrollment(this.props.enrollID, targetUuid).then(response => {
                this.setState({ enrollError: null, isEnrollWorking: false });
            }).catch(error => {
                this.setState({ enrollError: error.message, isEnrollWorking: false });
            });
            this.setState({ mergeModalIsOpen: false, isEnrollWorking: true });
        }
        else {
            this.setState({ mergeModalIsOpen: false });
        }
    }

    openNewJiraIssue = () => {
        this.setState({ isJiraWorking: true });
        let { data, enrollID } = this.props;
        let { uuid, name, visitId, reportSubmittedBy, visitData } = data;
        let { localDate: date, location } = visitData;
        let customFields = {};
        customFields.description = this.state.jiraIssueDescription;
        customFields.visitId = visitId;
        customFields.uuid = uuid;
        customFields.location = location.name;
        customFields.submittedBy = reportSubmittedBy;
        let issue = new JiraIssue('Enrollment', JiraProjects.ENRL, `${name}, ${location.name}, ${date}`, customFields);
        let getToken = this.props.firebase.functions.httpsCallable('proxy-jira-getToken');
        getToken().then((result) => {
            const token = result.data.token;
            return issue.push(token);
        }).then(jiraIssue => {
            return this.props.firebase.enrollment.updateEnrollmentWithJiraIssue(enrollID, jiraIssue.selfLink);
        }).then(() => {
            this.setState({ jiraIssueModalIsOpen: false, isJiraWorking: false });
        }).catch(error => {
            this.setState({ isJiraWorking: false });
            console.error(error);
        });
    }

    render() {
        const { showFailureDetails, isEditModalOpen, isEditWorking, editError, isEnrollWorking, enrollError, newRole, dismissReason, dismissModalIsOpen, mergeModalIsOpen, jiraIssueModalIsOpen, jiraIssueDescription, isJiraWorking, newCID } = this.state;
        let { data, enrollID } = this.props;

        let { customerId, uuid, name, role, status, visitId, notes, reportSubmittedBy, visitData: visit, jiraIssue } = data;
        let checks = data.preEnrollCheck;
        let checkIcons = this.checkIcon(checks);

        let { localDate: date, images, location, visitConfidence } = visit;

        let suggestionsForMerge = [];
        if (checks) {
            if (checks.name) {
                if (checks.customerId) {
                    if (checks.customerId.uuid !== checks.name.uuid) {
                        suggestionsForMerge = suggestionsForMerge.concat(checks.name);
                    }
                }
                else {
                    suggestionsForMerge = suggestionsForMerge.concat(checks.name);
                }
            }
            if (checks.customerId) {
                suggestionsForMerge = suggestionsForMerge.concat(checks.customerId);
            }
        }
        let newName = "";

        let editMode = (status === EnrollmentStatus.pending);
        return (
            <>
                <Table.Row error={!!(checks && checks.error)}>
                    <Table.Cell width="2">
                        <Link to={'/v/' + visitId} target="_blank"><Image rounded fluid src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${images[0].mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}`} data-tip="Open this visit in new tab" /></Link>
                    </Table.Cell>
                    <Table.Cell width="6">
                        <Header as="h3">
                            {name}
                            <Button compact basic color={jiraIssue && 'blue'} size="mini" floated="right" onClick={jiraIssue ? () => window.open(jiraIssue, '_blank') : () => this.setState({ jiraIssueModalIsOpen: true })}>{jiraIssue ? 'View on Jira' : 'Open Jira issue'}</Button>
                            <Modal open={jiraIssueModalIsOpen}>
                                <Modal.Header>Open new issue on Jira</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <Card fluid>
                                            <Card.Content header={`${name}, ${location.name}, ${date}`} />
                                            <Card.Content>
                                                <Grid>
                                                    <Grid.Column width='10'>
                                                        <Header size='small'>Describe the issue</Header>
                                                        <Form>
                                                            <TextArea placeholder='Describe the issue' value={jiraIssueDescription} onChange={e => this.setState({ jiraIssueDescription: e.target.value })} />
                                                        </Form>
                                                    </Grid.Column>
                                                    <Grid.Column width='6'>
                                                        <Grid.Row style={{ marginBottom: '6px' }}>
                                                            <Header size='tiny'>
                                                                VISIT ID
                                                                <Header.Subheader>{visitId}</Header.Subheader>
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row style={{ marginBottom: '6px' }}>
                                                            <Header size='tiny'>
                                                                PERSON UUID
                                                                <Header.Subheader>{uuid}</Header.Subheader>
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row style={{ marginBottom: '6px' }}>
                                                            <Header size='tiny'>
                                                                LOCATION
                                                                <Header.Subheader>{location.name}</Header.Subheader>
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row style={{ marginBottom: '6px' }}>
                                                            <Header size='tiny'>
                                                                ENROLLMENT SUBMITTED BY
                                                                <Header.Subheader>{reportSubmittedBy}</Header.Subheader>
                                                            </Header>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid>
                                            </Card.Content>
                                            <Card.Content extra>Preview</Card.Content>
                                        </Card>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => this.setState({ jiraIssueModalIsOpen: false })}>Cancel</Button>
                                    <Button color='orange' onClick={() => this.openNewJiraIssue()} loading={isJiraWorking}>Create issue on Jira</Button>
                                </Modal.Actions>
                            </Modal>
                            {
                                editMode &&
                                <Modal open={isEditModalOpen} centered={false} size="tiny" trigger={<Button compact basic size="mini" floated="right" onClick={() => this.setState({ isEditModalOpen: true })}>Edit</Button>}>
                                    <Modal.Header>Edit</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <Form.Group widths="equal">
                                                <Form.Input label='Name' placeholder={name} fluid onChange={e => newName = e.target.value} />
                                                <Form.Input label='Customer ID' placeholder={newCID !== null ? customerId : null} fluid onChange={e => this.setState({ newCID: e.target.value })} icon={<Icon name='remove' inverted circular link onClick={() => this.setState({ newCID: null })} />} />
                                            </Form.Group>
                                            <Form.Group inline>
                                                <label>Role</label>
                                                <Form.Radio
                                                    label='Customer'
                                                    value='default'
                                                    checked={newRole === 'default'}
                                                    onChange={(e, { value }) => this.setState({ newRole: value })}
                                                />
                                                <Form.Radio
                                                    label='Employee'
                                                    value='employee'
                                                    checked={newRole === 'employee'}
                                                    onChange={(e, { value }) => this.setState({ newRole: value })}
                                                />
                                            </Form.Group>
                                        </Form>
                                        <br />
                                        <Modal.Description>
                                            {
                                                editError && <Header color="red" as="h5">{editError}</Header>
                                            }
                                        </Modal.Description>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button color="red" basic disabled={isEditWorking} onClick={this.onEditCancel}>Cancel</Button>
                                        <Button color="orange" loading={isEditWorking} disabled={isEditWorking} onClick={() => this.onEdit(newName, newCID, newRole)}>Done</Button>
                                    </Modal.Actions>
                                </Modal>
                            }
                            <br />
                            <Header.Subheader>
                                <b>UUID: </b><UUID value={uuid} /><br />
                                <b>Salesforce ID: </b>{customerId ? customerId : <cite>Unavailable</cite>}<br />
                                <b>Visit ID: </b> {visitId}<br />
                                {reportSubmittedBy && <div><b>Submitted by: </b>{reportSubmittedBy}</div>}
                                {(checks && checks.error) && <Header as="h5" color="red">{checks.error.reason || checks.error}</Header>}
                                <ReactTooltip effect="solid" delayHide={100} />
                            </Header.Subheader>
                        </Header>
                        <Label.Group>
                            <Label>{role}</Label>
                            <Label>{location.name}</Label>
                            <Label>{Moment(visit.timestamp.toDate()).tz(location.timezone).format('l')}</Label>
                            <Label color={visitConfidence < 1.0 ? 'red' : null}>{(visitConfidence * 100).toFixed(2) + '%'}</Label>
                        </Label.Group>
                        {(notes || (!notes && editMode)) && <Note compact notes={notes} editMode={editMode} onEdit={this.onNoteEdited} />}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width="1">
                        <Icon inverted color={checkIcons.name.color} size="large" name={checkIcons.name.icon} onClick={checkIcons.name.onClick} data-tip={checkIcons.name.tip} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" width="1">
                        <Icon inverted color={checkIcons.customerId.color} size="large" name={checkIcons.customerId.icon} onClick={checkIcons.customerId.onClick} data-tip={checkIcons.customerId.tip} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" width="1">
                        <Icon inverted color={checkIcons.confidence.color} size="large" name={checkIcons.confidence.icon} onClick={checkIcons.confidence.onClick} data-tip={checkIcons.confidence.tip} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" width="1">
                        <Icon inverted color={checkIcons.visit.color} size="large" name={checkIcons.visit.icon} onClick={checkIcons.visit.onClick} data-tip={checkIcons.visit.tip} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" width="2">
                        {status === EnrollmentStatus.pending || status === EnrollmentStatus.error
                            ?
                            <>
                                {status !== EnrollmentStatus.error ?
                                    <Button.Group color="orange">
                                        <Button loading={isEnrollWorking} disabled={(!checks || !!checks.error || isEnrollWorking || status === EnrollmentStatus.error)} onClick={() => this.onEnrollClick(enrollID)}>Enroll</Button>
                                        <Dropdown item floating direction='right' className='button icon' disabled={(!checks || !!checks.error || isEnrollWorking)}>
                                            <Dropdown.Menu>
                                                <EnrollmentMergeModal trigger={<Dropdown.Item disabled={!checks} onClick={() => this.setState({ mergeModalIsOpen: true })}>Merge</Dropdown.Item>} mergeSuggestions={suggestionsForMerge} onMerge={this.doMerge} isOpen={mergeModalIsOpen} />
                                                <Dropdown.Divider />
                                                <DismissEnrollmentModal trigger={<Dropdown.Item onClick={() => this.setState({ dismissModalIsOpen: true })}>Dismiss</Dropdown.Item>} dismissReason={dismissReason} onReasonSelected={this.onDismissReasonSelected} onFreeTextReasonChanged={this.onFreeTextDismissReasonChanged} isOpen={dismissModalIsOpen} onAction={this.doDismissEnroll} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Button.Group>
                                    : <DismissEnrollmentModal trigger={<Button color="red" onClick={() => this.setState({ dismissModalIsOpen: true })}>Dismiss</Button>} dismissReason={dismissReason} onReasonSelected={this.onDismissReasonSelected} onFreeTextReasonChanged={this.onFreeTextDismissReasonChanged} isOpen={dismissModalIsOpen} onAction={this.doDismissEnroll} />
                                }
                            </>
                            : <Label circular basic color={EnrollmentLabelColor(status)}>{EnrollmentStatusName[status]}</Label>
                        }
                        {enrollError && <Header as="h5" color="red">{enrollError}</Header>}
                    </Table.Cell>
                </Table.Row>
                {showFailureDetails &&
                    <Table.Row>
                        <Table.Cell colSpan="1" textAlign="right"></Table.Cell>
                        <Table.Cell colSpan="6">
                            <EnrollmentDetails details={checks} enrollVisitID={visitId} compareItemWithLatestVisit={this.props.compareItemWithLatestVisit} />
                        </Table.Cell>
                    </Table.Row>
                }
            </>

        );
    }
}

const DismissEnrollmentModal = props => {
    const { isOpen, trigger, dismissReason, onReasonSelected, onFreeTextReasonChanged, onAction } = props;
    return (
        <Modal trigger={trigger} open={isOpen}>
            <Modal.Header /*style={{ backgroundColor: '#F2711D', color: 'white' }}*/>Enrollment cancellation</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as='h4'>This action will remove this enrollment from the queue and cannot be reversed</Header>
                    <Form>
                        <Form.Group inline><label>Please state the reason you wish to cancel this enrollment:</label></Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Radio}
                                label='False identification'
                                value={EnrollmentCancelReason.false}
                                checked={dismissReason.type === EnrollmentCancelReason.false}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Radio}
                                label='Duplicate'
                                value={EnrollmentCancelReason.duplicate}
                                checked={dismissReason.type === EnrollmentCancelReason.duplicate}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Radio}
                                label='Requires manual enrollment'
                                value={EnrollmentCancelReason.manual}
                                checked={dismissReason.type === EnrollmentCancelReason.manual}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Radio}
                                label='No-Op'
                                value={EnrollmentCancelReason.no_op}
                                checked={dismissReason.type === EnrollmentCancelReason.no_op}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Field control={TextArea} onChange={e => onFreeTextReasonChanged(e.target.value)} label='Detailed reason' placeholder='Please write more details about the reason you chose above' />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='grey' onClick={() => onAction(false)}><Icon name='remove' /> Cancel</Button>
                <Button color='orange' disabled={dismissReason.freeText === null || dismissReason.type === null} onClick={() => onAction(true)}><Icon name='user cancel' /> Remove from queue</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default compose(
    withFirebase
)(EnrollmentQueueItem);