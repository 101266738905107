import React, { Component } from 'react';
import { Image, Icon, Button, Modal } from "semantic-ui-react";

class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            working: false,
            duo: props.allowUserDeletion
        }

        this.actionStyle = {
            color: 'red',
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps !== this.props)
            this.setState(newProps);
    }

    deleteOnClick = () => {
        this.setState({ open: false });
        this.props.action();
    }

    cancel = () => {
        this.props.onCancel();
        this.setState({ open: false });
    }

    render() {
        return (
            <Modal size="mini" open={this.state.open} onClose={this.close}>
                <Modal.Header><Icon name='trash' color='red' /> Are you sure?</Modal.Header>
                <Modal.Content>
                    <h4>Are you sure you want to <strong style={this.actionStyle}>delete</strong> this visit from {this.props.visitDetails.datetime} at {this.props.visitDetails.location}?</h4>
                    <Image src={this.props.visitDetails.thumbnailURL} rounded fluid />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.cancel}>Cancel</Button>
                    <Button negative icon='trash' labelPosition='left' content='Delete' onClick={this.deleteOnClick} />
                </Modal.Actions>
            </Modal>
        );
    }
}


export default DeleteModal;