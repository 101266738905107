import React, { Component } from 'react';
import { compose } from "recompose";
import { Segment } from 'semantic-ui-react';
import ReportFeedback from './reportFeedback'

import Moment from "moment-timezone";

import { withLoading } from '../../Lists';

import { VisitView } from '../../Visit';

class ReportVisitsTable extends Component {

    render() {
        let { visits, transactionCandidates, transactionDataDelegate, filter, reportId, feedbackData, notesData, feedbackEdit, editMode, setVisitCompared, compareWith } = this.props;
        let visitsToDisplay = visits.filter((visit => (filter && filter.roles) ? filter.roles[visit.data.person.role] : true));
        return (
            <div>
                {visitsToDisplay.map(visit => {
                    return (
                        <Segment basic key={visit.id}>
                            <VisitView
                                visit={visit}
                                reportId={reportId}
                                deleteVisit={this.props.onVisistDelete}
                                editMode={editMode}
                                allowComparison={visits.length > 1}
                                setVisitCompared={setVisitCompared}
                                compareWith={compareWith}
                            >
                                {(feedbackData[visit.id] || transactionCandidates[visit.id]) &&
                                    <ReportFeedback
                                        visitId={visit.id}
                                        reportId={reportId}
                                        reportFeedback={feedbackData[visit.id]}
                                        reportNotes={notesData[visit.id]}
                                        candidates={transactionCandidates[visit.id] ? transactionCandidates[visit.id].map(candidate => Object.assign({ strongMatch: candidate.strongMatch },transactionDataDelegate(candidate.id)))                                          
                                        : []}
                                        transactionDataDelegate={transactionDataDelegate}
                                        visitRole={visit.data.person.role}
                                        editMode={feedbackEdit}
                                        visitMoment={Moment(visit.data.timestamp.toDate()).tz(visit.data.location.timezone)}
                                        onPreventAction={this.props.onPreventAction}
                                        visitTz={visit.data.location.timezone} />
                                }
                            </ VisitView >
                        </Segment>
                    )
                })}
            </div>
        );
    }
}
/* 
const loadingCondition = props =>
    props.isLoading;
 */

export default ReportVisitsTable;