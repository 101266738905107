import React, { Component } from 'react';
import { compose } from "recompose";
import { PropTypes } from 'prop-types';

import { Icon, Table, Segment, Header, Label } from 'semantic-ui-react';
import { TableFilter } from '../../UI';

import { withLoading } from '../../Lists';
import EnrollmentQueueItem from './enrollmentQueueItem';

import { EnrollmentStatusName, EnrollmentLabelColor, EnrollmentStatus } from "../../../constants/enrollment"

class EnrollmentQueueList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            statusSortingOptions: [],
            filters: {},
        }
    }

    static propTypes = {
        queue: PropTypes.array.isRequired,               //queue: The queue of items to enroll
        isLoading: PropTypes.bool.isRequired,            //isLoading: Determins if the list should show a spinner
        editNote: PropTypes.func.isRequired,             //editNote: Trigger to edit note
        cancelEnrollment: PropTypes.func.isRequired,     //cancelEnrollment: Cancel an enrollment item
        mergeEnrollment: PropTypes.func.isRequired,      //mergeEnrollment: Merges enrollments
        queuedCounter: PropTypes.number.isRequired,      //queuedCounter: Contains info about the number of items queued
        compareItemWithLatestVisit: PropTypes.func.isRequired,      //compareItemWithLatestVisit: Allows to compare an item with a person's latest visit
    };

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        const allStats = [...new Set(nextProps.queue.map(enroll => enroll.data.status))];
        let tempStatsOptions = [];
        allStats.forEach(status => {
            tempStatsOptions.push(
                {
                    key: status,
                    text: EnrollmentStatusName[status],
                    value: status,
                    icon: { color: EnrollmentLabelColor(status), name: "circle" },
                    default: status !== EnrollmentStatus.success,
                }
            );
        });
        this.setState({ statusSortingOptions: tempStatsOptions });
    }

    onFilter = (filter, field) => {
        let filters = this.state.filters;
        filters[field] = filter;
        this.setState({ filteres: filters });
    }

    render() {
        const { statusSortingOptions, filters } = this.state;
        let { queue, isLoading, queuedCounter } = this.props;
        let displayQueue = queue;
        if (filters.status) {
            displayQueue = displayQueue.filter(enroll => filters.status.includes(enroll.data.status));
        }
        const defaultFilters = [EnrollmentStatus.error, EnrollmentStatus.pending, EnrollmentStatus.queued, EnrollmentStatus.running, EnrollmentStatus.canceled];
        return (
            <Segment basic>
                <Header as="h2">Enrollment Queue {!isLoading && <Label color={queuedCounter > 0 ? 'orange' : 'green'}>{queuedCounter > 0 ? <><Icon loading name='spinner' /> {queuedCounter} items in queue</> : <Icon fitted name='check' />}</Label>}</Header>
                <Table celled structured unstackable color="orange">
                    {
                        !isLoading &&
                        <>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign="center"><Icon name="user circle outline" /></Table.HeaderCell>
                                    <Table.HeaderCell>Visit Data</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">CID</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Conf.</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Visit</Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">Action <TableFilter field='status' options={statusSortingOptions} onFilter={filter => this.onFilter(filter, 'status')} default={defaultFilters} /></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {displayQueue.map(enrollmentObject => (
                                    <EnrollmentQueueItem key={enrollmentObject.id}
                                        data={enrollmentObject.data}
                                        enrollID={enrollmentObject.id}
                                        onEdit={this.props.onEnrollEdited}
                                        onEnrollment={this.props.onEnrollment}
                                        editNote={this.props.editNote}
                                        cancelEnrollment={this.props.cancelEnrollment}
                                        mergeEnrollment={this.props.mergeEnrollment}
                                        compareItemWithLatestVisit={this.props.compareItemWithLatestVisit} />
                                ))}
                            </Table.Body>
                        </>
                    }
                </Table>
            </Segment>
        );
    }
}

const loadingCondition = props =>
    props.isLoading;

export default compose(
    withLoading(loadingCondition)
)(EnrollmentQueueList);