import React, { Component } from 'react';
import { compose } from "recompose";

import { withLoading, withPaginated, withInfiniteScroll } from '../Lists';

import VisitView from './VisitView'

class VisitsTable extends Component {

    render() {
        let { visits, filter, allowComparison, setVisitCompared, compareWith } = this.props;
        let editMode = !!this.props.editMode;
        let visitsToDisplay = visits.filter((visit => (filter && filter.roles) ? filter.roles[visit.data.person.role] : true))

        return (
            <div>
                <br />
                {visitsToDisplay.map(visit => (
                    <div key={visit.id}>
                        <VisitView
                            visit={visit}
                            deleteVisit={this.props.onVisistDelete}
                            editMode={editMode}
                            allowComparison={allowComparison}
                            setVisitCompared={setVisitCompared}
                            compareWith={compareWith}
                        />
                        <br />
                    </div>
                ))}
            </div>
        );
    }
}

const paginatedCondition = props =>
    props.page !== null && !props.isLoading && props.isError;

const infiniteScrollCondition = props =>
    (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)
    && props.visits.length
    && !props.isLoading
    && !props.isError;

const loadingCondition = props =>
    props.isLoading;


export default compose(
    withPaginated(paginatedCondition),
    withInfiniteScroll(infiniteScrollCondition),
    withLoading(loadingCondition)
)(VisitsTable);