const EnrollmentStatus = {
    pending: '00_pending',
    queued: '10_queued',
    running: '20_running',
    success: "30_success",
    canceled: '90_canceled',
    error: '99_error',
}

const EnrollmentStatusName = {
    '00_pending': 'pending',
    '10_queued': 'queued',
    '20_running': 'running',
    '30_success': 'success',
    '90_canceled': 'canceled',
    '99_error': 'error',
}

const EnrollmentLabelColor = status => {
    switch (status) {
        case EnrollmentStatus.error: return "red";
        case EnrollmentStatus.canceled: return "grey";
        case EnrollmentStatus.pending: return "yellow";
        case EnrollmentStatus.queued: return "orange";
        case EnrollmentStatus.running: return "blue";
        case EnrollmentStatus.success: return "green";
        default: return "grey";
    }
}

const EnrollmentCancelReason = {
    false: 'false',
    duplicate: 'duplicate',
    no_op: 'no_op',
    manual: 'manual',
};


export { EnrollmentStatus, EnrollmentStatusName, EnrollmentLabelColor, EnrollmentCancelReason };