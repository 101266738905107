import React, { Component, useState } from 'react';
//import { PropTypes } from 'prop-types';
import UIUtil from '../InterfaceUtilites';

import "./styles/richCard.scss"

const DefinedClassNames = {
    sideBySide: 'side-panel',
}

const CardMainPanel = props => {
    return (
        <div className="card-main">
            {props.children}
        </div>
    );
}

const CardMetadata = props => {
    return (
        <div className="card-metadata">
            {props.children}
        </div>
    );
}

const CardBody = props => {
    const [isImageLoaded, setIsImageLoadedState] = useState(false)
    const { image, } = props
    return (
        <div className="card-body">
            {image ? <center><img className="header-image" src={image} alt="thumbnail" style={{ background: 'lightgrey' }} /></center> : null}
            <div className="content-wrapper">
                {props.children}
            </div>
        </div>
    );
}

const CardExtendedPanel = props => {
    return (
        <div className="card-extended">
            {props.children}
        </div>
    )
}

class RichCard extends Component {
    state = {}

    static MainPanel = CardMainPanel;
    static ExtendedPanel = CardExtendedPanel;
    static Metadata = CardMetadata;
    static Content = CardBody;

    render() {
        let elementManagedClasses = "";
        const { sideBySide, children, } = this.props;
        if (sideBySide) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.sideBySide);
        }
        return (
            <div className={`card-wrapper${elementManagedClasses}`}>
                {children}
            </div>
        );
    }
}

export default RichCard;