import React, { Component } from 'react';
import { Item, Icon, Label } from "semantic-ui-react";

class QueueItem extends Component {
    state = {}
    render() {
        let operation = this.props.operation
        return (
            <Item>
                <Item.Image floated="left" size="small" src={this.props.operationendVisit.thumbnailURL} />
                <Item.Content verticalAlign="middle">
                    <Item.Header as='a'>{operation.operationCode.charAt(0).toUpperCase() + operation.operationCode.slice(1) + " operation"}</Item.Header>
                    <Item.Description><h4>Performed on: {operation.visitId}</h4></Item.Description>
                    <Item.Extra>
                        <Label color={operation.status === "success" ? "green" : (operation.status === "running" ? "orange" : "red")}>
                            <Icon loading={operation.status === "running"} name={operation.status === "success" ? "check" : (operation.status === "running" ? "spinner" : "exclamation triangle")} />
                            {operation.status === "success" ? "Completed" : (operation.status === "running" ? "In progress" : "Error")}
                        </Label>
                    </Item.Extra>
                </Item.Content>
            </Item>
        );
    }
}

export default QueueItem;