import React, { Component } from 'react';
import { Label, Segment, List, Header, Icon } from 'semantic-ui-react';
import QueueItem from './queueItem';

class OperationsQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queueAccent: this.props.remainingOps === 0 ? "green" : "orange",
            allowClose: this.props.remainingOps === 0 ? true : false
        }
    }
    render() {
        let keys = Object.keys(this.props.operationsToLstn);
        return (
            <Segment.Group>
                <Header color={this.state.queueAccent} block attached="top">
                    {this.state.allowClose && <div onClick={this.props.onClose}><Icon bordered inverted fitted size="small" color='grey' name='x' className="close-button" /></div>}
                    <Header.Content >
                        <Label empty circular color={this.state.queueAccent} /> Operations queue
                        <Header.Subheader>{this.props.remainingOps > 0 ? this.props.remainingOps + " operation(s) in progress" : "Done!"} </Header.Subheader>
                    </Header.Content>
                </Header>
                <Segment attached="bottom" className="ops-queue-list">
                    <List divided>
                        {keys.length === 0 && <Label attached="top"><h3>No operations in progress</h3></Label>}
                        {keys.map((opKey) => (
                            <List.Item key={opKey}>
                                <QueueItem
                                    operationID={opKey}
                                    operation={this.props.operationsToLstn[opKey].operationData}
                                    operationendVisit={this.props.operationsToLstn[opKey].opedVisitData} />
                            </List.Item>
                        ))}
                    </List>
                </Segment>
            </Segment.Group>
        );
    }
}

export default OperationsQueue;