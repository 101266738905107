
import "firebase/database";
import "firebase/auth"
import 'firebase/firestore';

import { ReportType } from '../../constants/reports'

class CaazamAPI {
    constructor(firebaseApp) {
        this.fsdb = firebaseApp.firestore();
        this.auth = firebaseApp.auth();
    }

    getFirestoreRef() {
        return this.fsdb;
    }

    preEnrollCheck(uuid, options) {
        var ok = false;

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            let body = {};
            if (options && options.name) {
                body.name = options.name;
            }
            if (options && options.customerId) {
                body.customerId = options.customerId;
            }
            if (options && options.role) {
                body.role = options.role;
            }
            if (options && options.visitId) {
                body.visitId = options.visitId;
            }
            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/person/' + uuid + '/pre_enroll_check', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(body)
            });
        }).then(response => {
            ok = (response.status === 200)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    enroll(enrollId) {
        var ok = false;
        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/enrollment/' + enrollId + '/enroll', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                //                body: JSON.stringify({ testOnly: true }),
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    enrollMerge(enrollId, targetUuid) {
        var ok = false;
        if (!targetUuid) {
            return Promise.reject(new Error('Cannot merge enrollment without targetUuid param'));
        }

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/enrollment/' + enrollId + '/merge', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ targetUuid: targetUuid }),
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    enrollCancel(enrollId, reason, notes) {
        var ok = false;
        if (!reason) {
            return Promise.reject(new Error('Cannot cancel enrollment without reason param'));
        }
        if (!notes) {
            return Promise.reject(new Error('Cannot cancel enrollment without notes param'));
        }

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/enrollment/' + enrollId + '/cancel', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ reason: reason, notes: notes }),
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    enrollUpdate(enrollId, name, customerId, role) {
        var ok = false;
        if (!name) {
            return Promise.reject(new Error('Cannot update enrollment without name param'));
        }
        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            let body = {};
            body.name = name;
            body.role = role;
            if (customerId) {
                body.customerId = customerId;
            }

            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/enrollment/' + enrollId, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(body),
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    enrollUpdateNotes(enrollId, notes) {
        var ok = false;

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            let body = { notes: notes };

            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + '/enroll/enrollment/' + enrollId + '/notes', {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(body),
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    deleteVisit(visitID, options) {
        var ok = false;
        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            let body = {};
            if (options && options.reportId) {
                body.reportId = options.reportId;
            }
            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + "/visit/" + visitID, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(body)
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    createReport(location, timeScopeObj, type = ReportType.visits_with_sales) {
        var ok = false;
        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {

            let body = {
                scope: {
                    location: location,
                    timeScope: {
                        from: timeScopeObj.from.toISOString(),
                        to: timeScopeObj.to.toISOString(),
                    }
                },
                type: type,
            };

            return fetch(process.env.REACT_APP_CAAZAM_REST_HOST + "/report", {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify(body)
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }
   
    listenToOperations(operationID, onChanged, onError) {
        let query = this.fsdb.collection('visitOps').doc(operationID);
        return query.onSnapshot(doc => {
            onChanged(doc.data());
        }, error => {
            onError(error);
        }, error => {
            console.error('CaazamAPI.listenToOperations():', error);
        });
    }

    searchPerson(searchString, limit = null) {
        var ok = false;
        if (!searchString) {
            return Promise.reject(new Error('Cannot search using empty search string'));
        } else if (searchString.length < 2) {
            return Promise.reject(new Error('Search term must be longer than one character '));
        }

        var searchParams = { query: searchString }
        if (!!limit) {
            searchParams.limit = limit;
        }
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/person/search');
        url.search = new URLSearchParams(searchParams);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    getPersonProfile({ uuid, customerId, options }) {
        var ok = false;
        if (!!uuid && uuid === '') {
            return Promise.reject(new Error('Cannot get person profile of invliad uuid'));
        }
        if (!!customerId && customerId === '') {
            return Promise.reject(new Error('Cannot get person profile of invliad customerId'));
        }
        if (!customerId && !uuid) {
            return Promise.reject(new Error('Cannot get person profile without valid uuid or customerId'));
        }

        var searchParams = (!!uuid) ? { uuid: uuid } : { customerId: customerId }
        if (!!options) {
            if (!!options.betaFlag) {
                searchParams.betaFlag = options.betaFlag;
            }
        }
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/person/profile');
        url.search = new URLSearchParams(searchParams);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    getPersonPurchases({ uuid, customerId, options }) {
        var ok = false;
        if (!!uuid && uuid === '') {
            return Promise.reject(new Error('Cannot get person profile of invliad uuid'));
        }
        if (!!customerId && customerId === '') {
            return Promise.reject(new Error('Cannot get person profile of invliad customerId'));
        }
        if (!customerId && !uuid) {
            return Promise.reject(new Error('Cannot get person profile without valid uuid or customerId'));
        }

        var searchParams = (!!uuid) ? { uuid: uuid } : { customerId: customerId }
        if (!!options) {
            if (!!options.limit) {
                searchParams.limit = options.limit;
            }
        }
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/person/purchases');
        url.search = new URLSearchParams(searchParams);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    searchProduct(searchString, limit = null) {
        var ok = false;
        if (!searchString) {
            return Promise.reject(new Error('Cannot search using empty search string'));
        } else if (searchString.length < 2) {
            return Promise.reject(new Error('Search term must be longer than one character '));
        }

        var searchParams = { query: searchString }
        if (!!limit) {
            searchParams.limit = limit;
        }
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/product/search');
        url.search = new URLSearchParams(searchParams);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }



    getProductProfile({ productId, options }) {
        var ok = false;
        if (!productId || productId === '') {
            return Promise.reject(new Error('Cannot get product profile of invliad productId'));
        }

        var searchParams = { productId: productId };
        if (!!options) {
            if (!!options.betaFlag) {
                searchParams.betaFlag = options.betaFlag;
            }
        }
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/product/profile');
        url.search = new URLSearchParams(searchParams);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                console.log(responseObj);
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    getClientRemoteConfig() {
        var ok = false;
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/clientConfig');

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
            });
        }).then(response => {
            ok = (response.status === 200 || response.status === 202)
            return response.json();
        }).then(responseObj => {
            if (ok) {
                return responseObj
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    setClientRemoteConfigParam(paramName, value) {
        var ok = false;
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/clientConfig/'+ paramName);

        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                json: true,
                body: JSON.stringify({
                    value: value,
                }),
            });
        }).then(responseObj => {
            ok = (responseObj.status === 200 || responseObj.status === 202)
            if (ok) {
                return;
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

    setClientRemoteConfigAll(config) {
        var ok = false;
        var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/clientConfig');
        return this.auth.currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
            return fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                json: true,
                body: JSON.stringify(config),
            });
        }).then(responseObj => {
            ok = (responseObj.status === 200 || responseObj.status === 202)
            if (ok) {
                return;
            } else {
                throw new Error(responseObj.error + ':' + responseObj.reason);
            }
        });
    }

}

export default CaazamAPI;