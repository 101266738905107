import React, { Component } from 'react';
import { Image, Modal } from 'semantic-ui-react';

import { ImageView } from "../../UI";

class ThumbnailPanel extends Component {
    //If cannot fetch thumbnail show default error image
    addDefaultSrc = (ev) => {
        if (this.props.onError) {
            if (!this.props.onError()) {
                ev.target.src = process.env.PUBLIC_URL + '/image_not_found_404.png'
            }
        } else {
            ev.target.src = process.env.PUBLIC_URL + '/image_not_found_404.png';
        }
    }

    render() {
        return (
            <Modal
                trigger={<ImageView src={this.props.imageSrcUrl} faceRect={this.props.faceRect} confidence={this.props.maxConfidence} onError={this.addDefaultSrc} style={{ cursor: "zoom-in" }} />}
                size='large'
                closeIcon
            >
                <Modal.Content image>
                    <Image src={this.props.fullImageSrcUrl} onError={this.addDefaultSrc} />
                </Modal.Content>
            </Modal>
        );
    }
}

export default ThumbnailPanel;