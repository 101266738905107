const IssueTypes = {
    "Enrollment": "10013"
}

class JiraIssue {
    constructor(type, project, summary, customFields) {
        this.isOpen = false;
        this.type = IssueTypes[type];
        this.project = project;
        this.summary = summary;
        this.customFields = customFields;

        //Available only after issue was pushed (isOpen)
        this.id = null;
        this.key = null;
        this.selfLink = null;
    }

    push(accessToken) {
        let requestHeaders = new Headers();
        requestHeaders.append("Content-Type", "application/json");
        requestHeaders.append("Authorization", `Bearer ${accessToken}`);

        let data = {
            update: {},
            fields: {
                issuetype: {
                    id: this.type
                },
                project: {
                    id: this.project
                },
                summary: this.summary,
                customfield_10030: this.customFields.visitId,
                customfield_10031: this.customFields.uuid,
                customfield_10032: this.customFields.location,
                customfield_10033: this.customFields.submittedBy,
                description: {
                    type: "doc",
                    version: 1,
                    content: [
                        this.customFields.description && {
                            type: "paragraph",
                            content: [
                                {
                                    text: this.customFields.description,
                                    type: "text"
                                }
                            ]
                        },
                        {
                            type: "paragraph",
                            content: [
                                {
                                    text: "~ Issued via the Caazam webapp",
                                    type: "text",
                                    marks: [
                                        {
                                            type: "code"
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
            }
        }

        let requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        return fetch(`https://api.atlassian.com/ex/jira/${/* cloud id */ '66c5585f-4886-4bb5-8d7f-b66488bfbc6b'}/rest/api/3/issue`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let remoteIssueMetadata = JSON.parse(result);
                this.isOpen = true;
                this.id = remoteIssueMetadata.id;
                this.key = remoteIssueMetadata.key;
                this.selfLink = `https://caazam.atlassian.net/projects/ENRL/issues/${remoteIssueMetadata.key}`;;
                return this;
            }).catch(error => console.log('error', error));
    }

}

export default JiraIssue;