import React, { Component } from 'react';
import { Item, Label, Table } from 'semantic-ui-react';
import { PersonRoles } from '../../../constants/roles';
import Moment from 'moment-timezone';
import UUID from '../../UI/uuid';

import PersonImageView from '../../UI/ImageView';

class VisitDetails extends Component {

    openFullResImage = imageUuid => {
        let win = window.open(`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${imageUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`, '_blank');
        win.focus();
    }

    render() {
        let visit = this.props.visit;
        let data = visit.data;
        let identifications = data.identifications;
        return (
            <Item.Group divided>
                {identifications.map(ident => {
                    let idDate = ident.timestamp.toDate();

                    return (
                        <Item key={ident.mediaUuid}>
                            <Item.Content><PersonImageView src={`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${ident.mediaUuid}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}`} faceRect={ident.faceRectNorm} onClick={() => this.openFullResImage(ident.mediaUuid)} style={{ cursor: "alias" }} /></Item.Content>
                            <Item.Content>
                                <Item.Header>
                                    <Label color={ident.role === PersonRoles.default ? 'orange' : null}>{ident.role}</Label>
                                    <Label >{parseFloat(Math.round(ident.confidence * 10000) / 100).toFixed(2) + "%"}</Label>
                                    <Label >{ident.faceRect.width}px</Label>
                                    <Label >{ident.faceAtt.gender}</Label>
                                    <Label color={ident.faceAtt.age < 13 ? 'red' : null} >age {ident.faceAtt.age}</Label>
                                </Item.Header>
                                <Item.Description>
                                    <Table attached='bottom' color='orange'>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>Capture Time</Table.Cell>
                                                <Table.Cell>{idDate.toISOString()} ({Moment(idDate).tz(data.location.timezone).format('l LT')}) </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Media UUID</Table.Cell>
                                                <Table.Cell>{ident.mediaUuid}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Face ID</Table.Cell>
                                                <Table.Cell>{ident.faceId}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>Person UUID</Table.Cell>
                                                <Table.Cell><UUID value={ident.uuid} /></Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    )
                })
                }
            </Item.Group>
        );
    }
}

export default VisitDetails;
