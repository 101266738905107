import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Button, Icon, Table, Header, Form, Radio, Input } from 'semantic-ui-react';
import UUID from '../../../UI/uuid';

class EnrollmentMergeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            otherUuid: '',
        }
    }

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,      //isOpen: Is open?
        trigger: PropTypes.object.isRequired,      //trigger: What triggers the modal to prompt
        mergeSuggestions: PropTypes.array.isRequired,      //mergeSuggestions: Suggestions for the merge
        onMerge: PropTypes.func.isRequired,      //onMerge: What happens when the user does merge
    };


    onRadioSelect = (e, data) => {
        this.setState({ selected: data.value });
    }

    merge = doConfirm => {
        if (doConfirm) {
            let targetUuid = this.state.selected;
            if (this.state.selected === 'other')
                targetUuid = this.state.otherUuid;
            this.props.onMerge(true, targetUuid);
        }
        else {
            this.props.onMerge(false);
        }
    }

    render() {
        const { selected, otherUuid } = this.state;
        let { isOpen, trigger, mergeSuggestions } = this.props;
        return (
            <Modal trigger={trigger} open={isOpen}>
                <Modal.Header /*style={{ backgroundColor: '#F2711D', color: 'white' }}*/>Merge</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header as='h4'>This action will merge this enrollment with another one you choose and cannot be reversed</Header>
                    </Modal.Description>
                    <Table compact celled definition>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">Customer ID</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">Role</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {mergeSuggestions && mergeSuggestions.map(person => (
                                <Table.Row key={person.uuid}>
                                    <Table.Cell collapsing>
                                        <Radio
                                            name={'selectMerge'}
                                            value={person.uuid}
                                            checked={selected === person.uuid}
                                            onChange={this.onRadioSelect}
                                        />
                                    </Table.Cell>
                                    <Table.Cell width='10'>
                                        <Header as='h4'>
                                            {person.data.name}
                                            <Header.Subheader><UUID value={person.uuid} /></Header.Subheader>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell width='4' textAlign="center">{person.data.customerId}</Table.Cell>
                                    <Table.Cell width='2' textAlign="center">{person.data.role}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Radio
                                        name={'selectMerge'}
                                        value={'other'}
                                        checked={selected === 'other'}
                                        onChange={this.onRadioSelect}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan='1'>
                                    <Form>
                                        <Form.Field>
                                            <label>Or merge with this UUID:</label>
                                            <Input placeholder='Enter UUID here' value={otherUuid} onChange={e => this.setState({ otherUuid: e.target.value })} disabled={selected !== 'other'} error={otherUuid === null || otherUuid === ""} />
                                        </Form.Field>
                                    </Form>
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='grey' onClick={() => this.merge(false)}><Icon name='remove' /> Cancel</Button>
                    <Button color='orange' disabled={selected === null || (selected === 'other' && (otherUuid === null || otherUuid === ""))} onClick={() => this.merge(true)}><Icon name='user cancel' /> Merge</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EnrollmentMergeModal;