import React, { Component } from 'react';
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";

import { Segment, Message, Loader, Icon, Divider } from 'semantic-ui-react';

import queryString from 'query-string';
import BetaRecommendationsComparison from './recommendations';
import CustomerSearch from '../../CustomerProfile/SearchCustomer';
import ProductSearch from './SearchProduct';


class BetaRecommendations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            isLoading: true,
            isError: false,
            errorMsg: null,
            isShowingRecommendations: false,
        }
    }

    createThumbnailsUrlArray = images => {
        let thumbnails = [];
        images.forEach(image => {
            thumbnails.push(`${process.env.REACT_APP_CAAZAM_REST_HOST}/images/${image.mediaUuid}/faces/${image.faceId}?key=${process.env.REACT_APP_CAAZAM_IMAGE_KEY}&size=size_orig`)
        });
        return thumbnails;
    }

    componentDidMount() {
        const queryParam = queryString.parse(this.props.location.search);
        this.loadProfile(queryParam);
        this.loadProduct(queryParam);
    }

    loadProfile = key => {
        const API = this.props.firebase.caazamAPI;
        var param = key.uuid ? { uuid: key.uuid } : key.cid ? { customerId: key.cid } : null;
        this.setState({ isLoading: true, isError: null });

        if (param) {
            if (process.env.REACT_APP_CAAZAM_BETA_RECOMMENDATIONS) {
                param.options = { betaFlag: true }
            }

            this.setState({ isShowingRecommendations: true });
            API.getPersonProfile(param).then(profileData => {
                if (profileData.images) {
                    profileData.thumbnails = this.createThumbnailsUrlArray(profileData.images);
                }
                if (profileData.lastPurchase) profileData.lastPurchase.date = new Date(profileData.lastPurchase.date);
                if (profileData.lastVisit) profileData.lastVisit.date = new Date(profileData.lastVisit.date);
                this.setState({ profile: profileData, isLoading: false, isError: null });
            }).catch(error => {
                console.error(error);
                this.setState({ isLoading: false, isError: true, errorMsg: error.message });
            });
        }
    }

    loadProduct = key => {
        const API = this.props.firebase.caazamAPI;
        var param = key.pid ? { productId: key.pid } : null;
        this.setState({ isLoading: true, isError: null });

        if (param) {
            if (process.env.REACT_APP_CAAZAM_BETA_RECOMMENDATIONS) {
                param.options = { betaFlag: true }
            }

            this.setState({ isShowingRecommendations: true });
            API.getProductProfile(param).then(productData => {
                this.setState({ profile: productData, isLoading: false, isError: null });
            }).catch(error => {
                console.error(error);
                this.setState({ isLoading: false, isError: true, errorMsg: error.message });
            });
        }
    }

    render() {
        const { profile, isLoading, isError, errorMsg, isShowingRecommendations } = this.state;
        return (
            <Segment basic padded>
                <Loader active={isShowingRecommendations && isLoading} inline='centered'>Loading recommendations</Loader>
                {isShowingRecommendations && !isLoading && profile &&
                    <>
                        <p onClick={() => this.setState({ isShowingRecommendations: false })} style={{ cursor: "pointer" }}><Icon name="arrow left" /> <u>Back to Search</u></p>
                        <BetaRecommendationsComparison {...profile} />
                    </>
                }
                {!isShowingRecommendations &&
                    <div>
                        <CustomerSearch onResultSelected={this.loadProfile} />
                        <Divider horizontal>OR</Divider>
                        <ProductSearch onResultSelected={this.loadProduct} />
                    </div>
                }
                {isError && !isLoading && <ErrorMessage errorMsg={errorMsg} />}

            </Segment>
        );
    }
}

const ErrorMessage = props => (
    <Message negative>
        <Message.Header>
            OOPS! There was an error{" "}
            <span role="img" aria-label="sorry face">
                😕
            </span>
        </Message.Header>
        <Message.Content>
            {props.errorMsg}
        </Message.Content>
    </Message>
)

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(BetaRecommendations);