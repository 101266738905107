export const LANDING = "/";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const REPORTS = "/reports";
export const REPORT_VIEW = "/reports/:reportId";
export const VISITS_BROWSER = "/visitsHistory";
export const VISIT_VIEW = "/v/:visitID";
export const ENROLLMENT = "/admin/enrollment";
export const COMPARE_VISITS = "/v/:visitID_A/compareWith/:visitID_B";
export const CUSTOMER_PROFILE = "/customers";
export const MISSED_VISITS = "/admin/missed-visits";
export const SYSTEM_STATUS = "/admin/system-status";
export const REMOTE_CONFIG_MANAGER = "/admin/rcm";

export const SANDBOX = "/sandbox";

export const BETA_RECOMMENDATIONS = "/admin/beta/recommendations";
export const BETA_FEED = "/home/beta";
